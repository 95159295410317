
.header {
    background-color: #2e2f30;
    min-height: 38vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: whitesmoke;
    border-bottom: solid rgba(128, 128, 128, 0.719);
}

.header > p {
    font-size: 20px;
    font-weight: 400;
}

.githubBtn {
    color: whitesmoke;
    background-color: #2e2f30;
    cursor: pointer;
    font-size: 20px;
    border-radius: 5px;
}

.githubBtn:hover {
    background-color: rgb(174, 0, 255);
}


.linkedInBtn {
    color: whitesmoke;
    background-color: #2e2f30;
    cursor: pointer;
    font-size: 20px;
    border-radius: 5px;
}

.linkedInBtn:hover {
    background-color: rgb(174, 0, 255);
}

.body {
    display: flex;
    flex-direction: row;
    background-color: #1e1e1e; 
}

.demoContainer {
    display: flex;
    transform: scale(0.8);
    margin-bottom: -70px;
    margin-left: 50px;
    width: 70%;
    /* align-items: start; */
    align-items: flex-start;
}

.demoGIF {
    width: 100%;
    margin-top: -90px;
    margin-left: -70px;
    margin-right: -75px;
    border-top: solid #1e1e1e;
}

.textContainer {
    display: flex;
    flex-direction: row;
    border-left: solid rgba(169, 169, 169, 0.179);
    width: 40%;
}

.demoText {
    color: rgb(224, 222, 222);
    font-size: 20px;
    font-weight: 300;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding-left: 1.2em;
    padding-right: 1em;
}  

.demoText > h1 {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding: 20px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 300;
    font-size: 34px;
}

.featuresToCome {
    font-size: 19px;
    font-style: italic;
    padding-left: 1em;
    list-style: none;
    padding: 20px;
}

.featuresToCome > li:before {
    content: "+";
    margin-right: 5px;
    font-weight: 500
}

.footerHeader {
    background-color: #2e2f30;
    font-size: 28px;
    color: whitesmoke;
    padding: 20px;
    display: flex;
    justify-content: center; 
    object-fit: fill;
    border-top: solid rgba(128, 128, 128, 0.719);
}

.footerContainer {
    background-color: #2e2f30;
    min-height: 20vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.avatarContainer > img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin: 10px;
    padding: 8px;
    display: flex;
}

.avatarContainer > p {
    color: whitesmoke;
    display: flex;
    font-size: 16px;
    font-weight: 350;
    justify-content: center;
}

.avatarIcons {
    display: flex;
    justify-content: center;
    align-content: flex-start;
    margin: 10px;
    gap: 7px;
    cursor: pointer;
    font-size: large;
}

.avatarIcons > a:hover {
    font-size: 120%;
}


.closingTag {
    background-color: #2e2f30;
    color: rgb(142, 140, 140);
    display: flex;
    justify-content: center;
    padding: 20px;
    font-size: 14px;
    font-weight: 300;
}


   
    